import React, { useState,useEffect ,useRef} from 'react';
import Footer from '../../src/components/footer'
import logo from '../assets/logo.png';
import { useGetFAQCategoriesQuery } from "../redux/api/FAQ";
import { useGetFAQContactUsQuery } from "../redux/api/FAQ";
import FAQ_Box from '../components/faq_box';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import MobileMenu from '../components/MobileMenu';
import faq from '../assets/faq.svg';

function FAQ (){
  const [allcategories, setAllCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showScroll, setShowScroll] = useState(false);
    const scrollLinkRef = useRef(null);
  const { data: AllCategoriesResponse} = useGetFAQCategoriesQuery();
  const { data: ContactUsResponse} = useGetFAQContactUsQuery();
  const [fontSize, setFontSize] = useState('30px');
  const [fontSize2, setFontSize2] = useState('25px');
  // Function to update font size based on window width
  const updateFontSize = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth < 768) {
      setFontSize('5px');
      setFontSize2('5px');// Small screen size
    } else {
      setFontSize('30px');
      setFontSize2('25px') // Default font size
    }
  };
    useEffect(() => {
      if (AllCategoriesResponse?.data) {
        setAllCategories(AllCategoriesResponse.data);
        setLoading(false); 
      } else {
        setLoading(true); 
      }
    }, [AllCategoriesResponse]);
    const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768); // Change layout for screens <= 768px
    };

    window.addEventListener('resize', handleResize);

    // Run the handler once to detect the initial screen size
    handleResize();

    // Clean up event listener on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

    const styles = {
      container: {
        display: 'flex',
        flexDirection: isSmallScreen ? 'column' : 'row', // Change flexDirection based on screen size
        alignItems: 'center',
        justifyContent: 'center',
        padding: '30px',
        maxWidth: '2000px',
        flexWrap: 'wrap',
        margin: '0 auto',
        backgroundColor: 'white',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)', // Box shadow effect
        border: 'none', // Remove any border
        gap: '20px',
      },
      textContainer: {
        flex: 1,
        textAlign: isSmallScreen ? 'center' : 'left', // Center the text for small screens
        maxWidth: '600px',
      },
      title: {
        fontSize: '35px', // Replace with your actual fontSize variables
        fontWeight: 'bold',
        color: '#333',
        marginBottom: '20px',
      },
      subtitle: {
        fontSize: '18px', // Replace with your actual fontSize2 variables
        color: '#666',
        marginBottom: '30px',
      },
      button: {
        padding: '10px 20px',
        backgroundColor: '#002855',
        color: 'white',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '1rem',
      },
      imageContainer: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        maxWidth: '400px',
      },
      image: {
        maxWidth: '100%',
        height: 'auto',
        borderRadius: '50%',
      },
    };
    
    
  return (
    <div className="page-wrapper">
      <header className="main-header style-three">
  <div className="header-upper">
    <div className="auto-container">
      <div className="inner-container clearfix">
        <div className="pull-left logo-box">
          <div className="logo">
            <Link to="/">
              <img src={logo} alt="" title="" width={150} height={100} />
            </Link>
          </div>
        </div>

        <div className="nav-outer pull-left clearfix">
          <nav className="main-menu navbar-expand-md">
            <div className="navbar-header">
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
                <span className="icon-bar"></span>
              </button>
            </div>

            <div
              className="navbar-collapse show collapse clearfix"
              id="navbarSupportedContent"
            >
              <ul className="navigation clearfix">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about">About Us</Link>
                </li>
                <li className="dropdown">
                  <Link to="#">Service</Link>
                  <ul>
                    <li>
                      <Link to="/service">Service</Link>
                    </li>
                    <li>
                      <Link to="/service-detail">Service Detail</Link>
                    </li>
                  </ul>
                </li>
                <li className="dropdown">
                  <Link to="#">Blog</Link>
                  <ul className="from-right">
                    <li>
                      <Link to="/blog">Our Blog</Link>
                    </li>
                    <li>
                      <Link to="/blog-detail">Blog Detail</Link>
                    </li>
                  </ul>
                </li>
                <li className="current">
  <Link to="/faq" style={{ 
    position: 'relative', 
    color: '#42C596', 
    textDecoration: 'none' 
  }}>
    FAQ
    <span style={{
      position: 'absolute',
      left: 0,
      bottom: '30px',
      width: '100%',
      height: '2px',
      backgroundColor: '#42C596',
    }}></span>
  </Link>
</li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </div>
          </nav>
    

<MobileMenu/>
        </div>
      </div>
    </div>
  </div>
</header>
<motion.div>
    <div style={styles.container}>
      <div style={styles.textContainer}>
        <h2 style={styles.title}>How can we better <br/>support you?</h2>
        <p style={styles.subtitle}>Search our knowledge base for answers to common questions.</p>
      </div>
      <div style={styles.imageContainer}>
        <img
          src={faq}
          style={styles.image}
        />
      </div>
    </div>
    </motion.div>
    <motion.div
      className="container py-4 shadow-sm mt-4"
      style={{ maxWidth: '1000px', padding: '20px', cursor: 'pointer',marginBottom:'50px' }}
      initial={{ opacity: 0, scale: 1 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 1, ease: "easeOut" }}
    >
      {loading ? (
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <motion.div
          className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-4"
          initial="hidden"
          animate="visible"
          variants={{
            hidden: { opacity: 0, y: 20 },
            visible: {
              opacity: 1,
              y: 0,
              transition: {
                staggerChildren: 0.1, // Animates child components one by one
              },
            },
          }}
        >
          {allcategories?.map((category, index) => (
            <motion.div
              key={index}
              variants={{
                hidden: { opacity: 0, y: 20 },
                visible: { opacity: 1, y: 0 },
              }}
            >
              <div
  style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '5px',
  }}
>
  <FAQ_Box key={index} allcategories={category} />
</div>

             
            </motion.div>
          ))}
        </motion.div>
      )}
    </motion.div>
    {loading ? (
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
    <div style={styles.container}>
      <div style={styles.textContainer}>
        <h2 style={styles.title}>{ContactUsResponse?.data?.title}</h2>
        <p style={styles.subtitle}>{ContactUsResponse?.data?.subtitle}</p>
        <button style={styles.button}><Link to='/contact' style={{color:'white'}}>{ContactUsResponse?.data?.button_name}</Link></button>
      </div>
      <div style={styles.imageContainer}>
        <img
          src={ContactUsResponse?.data?.image || 'default-image-url'} 
          alt="Contact Us" 
          style={styles.image}
        />
      </div>
    </div>
      )}
      <Footer />
      <div
        ref={scrollLinkRef}
        className="scroll-to-top"
        data-target="html"
        style={{ display: showScroll ? 'block' : 'none' }}
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
      >
        <span className="fa fa-arrow-circle-up"></span>
      </div>
    </div>
  );
};

export default FAQ;
