import React, { useState,useRef } from 'react';
import Footer from '../../src/components/footer'
import Plan from '../../src/assets/plan.png';
import Hand from '../../src/assets/hand.png';
import Tax from '../../src/assets/tax.png';
import Bank from '../../src/assets/bank.png';
import { Carousel } from 'react-bootstrap';
import Test from '../../src/assets/test.png';
import T1 from '../../src/assets/t1.jpg';
import T2 from '../../src/assets/t2.jpg';
import T3 from '../../src/assets/t3.jpg';
import Header from '../../src/components/header'
import { Link } from 'react-router-dom';

function Service (){
    const [showScroll, setShowScroll] = useState(false);
    const scrollLinkRef = useRef(null);
  return (
    <div className="page-wrapper">
    <Header/>


    <div class="service-banner-section" data-bg-image="images/background/service-bg.jpg">
		<div class="auto-container">
			<div class="content-box">
				<h2>Our <span>Services</span></h2>
				<div class="text">Capitalise on low hanging fruit to identify a ballpark value added activity to beta test. Override the digital divide with additional base clickthroughs.</div>
				<Link to="/contact" class="theme-btn btn-style-one"><span class="txt">get in touch</span></Link>
				
			
				<div class="lower-box clearfix">
					<div class="pull-left">
						<div class="book">
							<span class="icon icofont-phone"></span>
							book through <br/> call now
						</div>
					</div>
					<div class="pull-right">
						<Link class="phone" to="tel:+53-8698-50-50">+961 76 157 762</Link>
					</div>
				</div>
				
			</div>
			
		</div>
	</div>
    <div className="services-section" style={{ padding: '20px 0' }}>
                    <div className="auto-container" style={{ padding: '0 15px' }}>
                        <div className="sec-title" style={{ marginBottom: '20px' }}>

                            <h2><span>Best Solutions</span> For Your Business</h2>
                        </div>
                        <div className="row no-gutters">
                            {/* Left Section */}
                            <div className="col-lg-4 col-md-12 left-section">
                                <div className="service-info">
                                    <h2>Our Services</h2>
                                    <p style={{ fontSize: '20px' }}>Comprehensive Financial Planning and Analysis</p>
                                    <div className="learn-more" style={{ marginBottom: '10px', borderRadius: '5px' }}>
                                        <Link to="learn-more.html" className="btn btn-success">Learn More</Link>
                                    </div>
                                </div>
                            </div>
                            {/* Right Section: Services */}
                            <div className="col-lg-8 col-md-12 right-section" style={{
    border: '2px solid #ddd',
    padding: '0',
    marginBottom: '50px',
    display: 'flex',
    borderRadius: '5px',
    flexWrap: 'wrap',
    height: '100%',
    maxWidth: '100%', // Ensure it doesn't exceed 100%
}}>

<style>
    {`
        @media (max-width: 576px) {
            .right-section {
                width: 100%; // Adjust width for small screens
                height: auto; // Change to auto to fit content
                margin: auto; // Center the section
                padding: 0; // Remove padding around the section
            }
            .service-block {
                padding: 5px; // Minimal padding for service blocks
                margin: 0; // Remove margin if needed
            }
            .inner-box {
                padding: 5px; // Minimal padding for inner boxes
                height: auto; // Allow height to adjust
            }
            .text {
                font-size: 0.8em; // Adjust text size for better fit
            }
        }
    `}
</style>

<div className="row g-0" style={{ width: '100%' }}>
    {/* Financial Planning Block */}
    <div className="col-lg-6 col-md-6 col-sm-12" style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0',
        borderRight: '1px solid #ddd',
        borderBottom: '1px solid #ddd',
        height: 'auto', // Change to auto for better fit
    }}>
        <div className="service-block" style={{ flex: 1 }}>
            <div className="inner-box" style={{ padding: '5px', textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <div className="icon-box">
                    <img src={Plan} alt="about" />
                </div>
                <h5><Link to="/service-detail">Financial Planning</Link></h5>
                <div className="text">Platform omnichannel click thought leadership pivot</div>
                <Link className="read-more" to="/service-detail">More <span className="ti-angle-right"></span></Link>
            </div>
        </div>
    </div>

    {/* Investment Advisory Block */}
    <div className="col-lg-6 col-md-6 col-sm-12" style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0',
        borderRight: '1px solid #ddd',
        borderBottom: '1px solid #ddd',
        height: 'auto', // Change to auto for better fit
    }}>
        <div className="service-block" style={{ flex: 1 }}>
            <div className="inner-box" style={{ padding: '5px', textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <div className="icon-box">
                    <img src={Hand} alt="about" />
                </div>
                <h5><Link to="/service-detail">Investment Advisory</Link></h5>
                <div className="text">Disrupt taste makers help council emerging trends in financial markets.</div>
                <Link className="read-more" to="/service-detail">More <span className="ti-angle-right"></span></Link>
            </div>
        </div>
    </div>

    {/* Tax Planning Block */}
    <div className="col-lg-6 col-md-6 col-sm-12" style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0',
        borderRight: '1px solid #ddd',
        borderBottom: '1px solid #ddd',
        height: 'auto', // Change to auto for better fit
    }}>
        <div className="service-block" style={{ flex: 1 }}>
            <div className="inner-box" style={{ padding: '5px', textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <div className="icon-box">
                    <img src={Tax} alt="about" />
                </div>
                <h5><Link to="/service-detail">Tax Planning</Link></h5>
                <div className="text">Mobile ready proprietary dedication intuitive solutions that streamline tax strategies.</div>
                <Link className="read-more" to="/service-detail">More <span className="ti-angle-right"></span></Link>
            </div>
        </div>
    </div>

    {/* Corporate Finance Block */}
    <div className="col-lg-6 col-md-6 col-sm-12" style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0',
        height: 'auto', // Change to auto for better fit
    }}>
        <div className="service-block" style={{ flex: 1 }}>
            <div className="inner-box" style={{ padding: '5px', textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <div className="icon-box">
                    <img src={Bank} alt="about" />
                </div>
                <h5><Link to="/service-detail">Corporate Finance</Link></h5>
                <div className="text">Thought leadership drives innovative solutions that enhance business performance max.</div>
                <Link className="read-more" to="/service-detail">More <span className="ti-angle-right"></span></Link>
            </div>
        </div>
    </div>
</div>
</div>


                        </div>
                    </div>
                </div>

                <div className="reputation-section-two style-two">
                    <div className="auto-container">
                        <div className="row clearfix">


                            <div className="form-column col-lg-5 col-md-12 col-sm-12">
                                <div className="inner-column">
                                    <div className="form-boxed">
                                        <h5>free consulation</h5>

                                        <div className="consult-form">
                                            <form method="post" action="donate.html">


                                                <div className="form-group">
                                                    <label>full name</label>
                                                    <input type="text" name="name" placeholder="Enter Your Full Name" required />
                                                </div>

                                                <div className="form-group">
                                                    <label>phone number</label>
                                                    <input type="text" name="phone" placeholder="Enter your phone number" required />
                                                </div>

                                                <div className="form-group">
                                                    <label>topics</label>
                                                    <select className="custom-select-box">
                                                        <option>Choose topic</option>
                                                        <option>Topic 01</option>
                                                        <option>Topic 02</option>
                                                        <option>Topic 03</option>
                                                        <option>Topic 04</option>
                                                    </select>
                                                </div>

                                                <div className="form-group">
                                                    <label>message</label>
                                                    <textarea name="message" placeholder="Write your message here"></textarea>
                                                </div>
                                                <div className="form-group">
                                                    <button className="theme-btn btn-style-one" type="submit" name="submit-form"><span className="txt">send your messenger</span></button>
                                                </div>
                                            </form>

                                        </div>
                                    </div>

                                </div>
                            </div>


                            <div className="content-column col-lg-7 col-md-12 col-sm-12">
                                <div className="inner-column">
                                    <div className="sec-title">
                                        <div className="title">why choose us</div>
                                        <h2><span>Your Successful </span>Is<br></br> Our Reputation</h2>
                                    </div>
                                    <div className="blocks-outer">


                                        <div className="reputation-block">
                                            <div className="inner-box">
                                                <h5>Strong Martket Analysis</h5>
                                                <div className="text">Tasks, docs, and files integrate with Arado’s chat to close the <br></br> gaps between feedback and action.</div>
                                            </div>
                                        </div>


                                        <div className="reputation-block">
                                            <div className="inner-box">
                                                <h5>Experience & Percision</h5>
                                                <div className="text">Create multiple discussions to keep all relevant conversations <br></br> together, all in one place with Arado</div>
                                            </div>
                                        </div>


                                        <div className="reputation-block">
                                            <div className="inner-box">
                                                <h5>Experts About Business</h5>
                                                <div className="text">Create multiple discussions to keep all relevant conversations <br></br> together, all in one place with Arado</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <section
                    style={{
                        backgroundColor: '#4ab87f',
                        color: 'white',
                        padding: '10px', // Reduced padding
                    }}
                    className="testimonial-section"
                >
                    <div className="auto-container">
                        <div className="row mb-3"> {/* Reduced margin-bottom */}
                            <div
                                className="col-md-5 d-flex flex-column align-items-center text-center"
                                style={{ marginBottom: '1em' }} // Reduced margin-bottom
                            >
                                <br />
                                <img
                                    src={Test}
                                    alt="Client Image"
                                    style={{
                                        width: '150px',
                                        marginBottom: '10px', // Reduced margin-bottom
                                    }}
                                />
                                <h2
                                    className="display-4"
                                    style={{ fontSize: '2rem', marginBottom: '5px' }} // Reduced font size and margin
                                >
                                    4.5
                                </h2>
                                <div
                                    className="d-flex justify-content-center mb-2" // Reduced margin-bottom
                                    style={{ fontSize: '1.5rem' }}
                                >
                                    {[...Array(4)].map((_, i) => (
                                        <span key={i} style={{ color: '#ffc107', marginRight: '3px' }}> {/* Reduced margin-right */}
                                            &#9733;
                                        </span>
                                    ))}
                                    <span
                                        className="half-star"
                                        style={{ position: 'relative', display: 'inline-block', marginRight: '3px' }} // Reduced margin-right
                                    >
                                        <span style={{ color: '#ffc107' }}>&#9733;</span>
                                        <span
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                overflow: 'hidden',
                                                width: '50%',
                                                color: '#6c757d',
                                            }}
                                        >
                                            &#9733;
                                        </span>
                                    </span>
                                </div>
                                <h5 style={{ fontWeight: 'bold', fontSize: '1rem' }}> {/* Reduced font size */}
                                    Client Success: Highlight how your firm helped clients
                                </h5>
                                <p style={{ maxWidth: '80%', fontSize: '0.8rem', lineHeight: '1.4rem' }}> {/* Reduced font size and line-height */}
                                    Full range of consultancy & training for data consultation strategic ways for business.
                                </p>
                            </div>

                            <div className="col-md-1 d-flex justify-content-center">
                                <div
                                    style={{
                                        width: '1px',
                                        height: '100%',
                                        backgroundColor: '#6c757d',
                                        margin: 'auto',
                                    }}
                                ></div>
                            </div>

                            <div className="col-md-6">
                                <br />
                                <br />
                                <Carousel controls={false} indicators={true}>
                                    <Carousel.Item>
                                        <div className="d-flex flex-column justify-content-center text-center">
                                            <h5
                                                style={{
                                                    textTransform: 'uppercase',
                                                    color: '#6c757d',
                                                    fontSize: '1rem',
                                                }}
                                            >
                                                Client Stories
                                            </h5>
                                            <h2 style={{ fontWeight: 'bold', fontSize: '1.75rem' }}>
                                                Achieving Financial Goals Together
                                            </h2>
                                            <p
                                                style={{
                                                    marginTop: '15px',
                                                    fontSize: '0.9rem',
                                                    lineHeight: '1.5rem',
                                                }}
                                            >
                                                There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.
                                            </p>
                                            <div className="d-flex align-items-center justify-content-center mt-4">
                                                <img
                                                    src={T1}
                                                    alt="Author Image"
                                                    style={{
                                                        borderRadius: '50%', marginRight: '15px', width: '70px',
                                                        height: '70px',
                                                    }}
                                                />
                                                <div>
                                                    <h6 style={{ margin: 0, fontWeight: 'bold', fontSize: '1rem' }}>
                                                        Jesonal Jelins
                                                    </h6>
                                                    <span style={{ color: '#6c757d', fontSize: '0.85rem' }}>
                                                        Team Captain
                                                    </span>
                                                </div>
                                            </div>
                                            <div>
                                                <h6 style={{ margin: 0, fontWeight: 'bold', fontSize: '1rem', color: '#4ab87f' }}>
                                                    .
                                                </h6>

                                                <h6 style={{ margin: 0, fontWeight: 'bold', fontSize: '1rem', color: '#4ab87f' }}>
                                                    .
                                                </h6>
                                            </div>
                                        </div>
                                    </Carousel.Item>

                                    <Carousel.Item>
                                        <div className="d-flex flex-column justify-content-center text-center">
                                            <h5
                                                style={{
                                                    textTransform: 'uppercase',
                                                    color: '#6c757d',
                                                    fontSize: '1rem',
                                                }}
                                            >
                                                Client Stories
                                            </h5>
                                            <h2 style={{ fontWeight: 'bold', fontSize: '1.75rem' }}>
                                                Empowering Clients for Success
                                            </h2>
                                            <p
                                                style={{
                                                    marginTop: '15px',
                                                    fontSize: '0.9rem',
                                                    lineHeight: '1.5rem',
                                                }}
                                            >
                                                Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.
                                            </p>
                                            <div className="d-flex align-items-center justify-content-center mt-4">
                                                <img
                                                    src={T2}
                                                    alt="Author Image"
                                                    style={{
                                                        borderRadius: '50%', marginRight: '15px', width: '70px',
                                                        height: '70px',
                                                    }}
                                                />
                                                <div>
                                                    <h6 style={{ margin: 0, fontWeight: 'bold', fontSize: '1rem' }}>
                                                        Anna Smith
                                                    </h6>
                                                    <span style={{ color: '#6c757d', fontSize: '0.85rem' }}>
                                                        Project Lead
                                                    </span>
                                                </div>
                                            </div>
                                            <div>
                                                <h6 style={{ margin: 0, fontWeight: 'bold', fontSize: '1rem', color: '#4ab87f' }}>
                                                    .
                                                </h6>

                                                <h6 style={{ margin: 0, fontWeight: 'bold', fontSize: '1rem', color: '#4ab87f' }}>
                                                    .
                                                </h6>
                                            </div>
                                        </div>
                                    </Carousel.Item>

                                    <Carousel.Item>
                                        <div className="d-flex flex-column justify-content-center text-center">
                                            <h5
                                                style={{
                                                    textTransform: 'uppercase',
                                                    color: '#6c757d',
                                                    fontSize: '1rem',
                                                }}
                                            >
                                                Client Stories
                                            </h5>
                                            <h2 style={{ fontWeight: 'bold', fontSize: '1.75rem' }}>
                                                Building Long-Term Financial Security
                                            </h2>
                                            <p
                                                style={{
                                                    marginTop: '15px',
                                                    fontSize: '0.9rem',
                                                    lineHeight: '1.5rem',
                                                }}
                                            >
                                                The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum".
                                            </p>
                                            <div className="d-flex align-items-center justify-content-center mt-4">
                                                <img
                                                    src={T3}
                                                    alt="Author Image"
                                                    style={{
                                                        borderRadius: '50%', marginRight: '15px', width: '70px',
                                                        height: '70px',
                                                    }}
                                                />
                                                <div>
                                                    <h6 style={{ margin: 0, fontWeight: 'bold', fontSize: '1rem' }}>
                                                        Mark Johnson
                                                    </h6>
                                                    <span style={{ color: '#6c757d', fontSize: '0.85rem' }}>
                                                        Senior Consultant
                                                    </span>
                                                </div>
                                            </div>
                                            <div>
                                                <h6 style={{ margin: 0, fontWeight: 'bold', fontSize: '1rem', color: '#4ab87f' }}>
                                                    .
                                                </h6>

                                                <h6 style={{ margin: 0, fontWeight: 'bold', fontSize: '1rem', color: '#4ab87f' }}>
                                                    .
                                                </h6>
                                            </div>
                                        </div>
                                    </Carousel.Item>
                                </Carousel>
                            </div>
                        </div>
                    </div>
                </section>
      <Footer />
      <div
        ref={scrollLinkRef}
        className="scroll-to-top"
        data-target="html"
        style={{ display: showScroll ? 'block' : 'none' }}
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
      >
        <span className="fa fa-arrow-circle-up"></span>
      </div>
    </div>
  );
};

export default Service;
