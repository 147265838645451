import React,{useState,useRef} from 'react';
import Footer from '../../src/components/footer'
import Service1 from '../assets/service-1.jpg';
import Graph from '../assets/graph.jpg';
import Plan from '../../src/assets/plan.png';
import Hand from '../../src/assets/hand.png';
import Tax from '../../src/assets/tax.png';
import Bank from '../../src/assets/bank.png';
import Header from '../../src/components/header'
import { Link } from 'react-router-dom';

function ServiceDetail (){
	const [showScroll, setShowScroll] = useState(false);
    const scrollLinkRef = useRef(null);
  return (
    <div className="page-wrapper">
     <Header/>


    <div className="sidebar-page-container">
    	<div className="auto-container">
		
			<div className="service-title-box">
				<h2>Business Services Consulting</h2>
			</div>
        	<div className="row clearfix">
            	
           
                <div className="content-side col-lg-9 col-md-12 col-sm-12">
					<div className="service-detail">
						<div className="inner-box">
							<div className="image">
								<img src={Service1} alt="" />
							</div>
							<div className="lower-content">
								<p>We work closely with Employers across all industry sectors to ensure that their internal sed Human Resource systems processes align to their business requirements idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the great explorer of the truth.</p>
								<p>Take a 360-degree sed view of yours situations using our seds deep experience, industries specialization and global reach ensure that their internal sed Human Resource systems processes align to their business requirements idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings ensure that their internal sed Human Resource systems processes align to their business requirements ut idea of denouncing pleasure and praising pain was born and I will give you a complete account seds expound the actual teachings</p>
								<blockquote>
									HasTech – we help companies assess their skills and choose a new direction which utilizes the talents of the team and resources most productively.
								</blockquote>
								<h4>Specific Services</h4>
								<div className="row clearfix">
									
								
									<div className="service-block style-two col-lg-4 col-md-6 col-12">
										<div className="inner-box">
											<div className="icon-box">
												<span className="icon ti-user"></span>
											</div>
											<h5><Link to="/service-detail">Talent & HR Analytics</Link></h5>
											<div className="text">Which of ever undertke laborious physical exercised excepts.</div>
										</div>
									</div>
									
								
									<div className="service-block style-two col-lg-4 col-md-6 col-12">
										<div className="inner-box">
											<div className="icon-box">
												<span className="icon ti-pie-chart"></span>
											</div>
											<h5><Link to="/service-detail">Fraud & Risk Analytics</Link></h5>
											<div className="text">Great explorer the master builder working human happiness.</div>
										</div>
									</div>
									
								
									<div className="service-block style-two col-lg-4 col-md-6 col-12">
										<div className="inner-box">
											<div className="icon-box">
												<span className="icon ti-stats-up"></span>
											</div>
											<h5><Link to="/service-detail">Marketing Analytics</Link></h5>
											<div className="text">Chooses to enjoy a pleasure that has no anoing consequences</div>
										</div>
									</div>
									
								</div>
								
						
								<div className="analysis-columns">
									<div className="row clearfix">
										
										<div className="column col-lg-6 col-md-6 col-sm-12">
											<h4>Advantages Of Services</h4>
											<p>Beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue beguiled and demoralized by the charms of pleasure of the moment, so blinded by desire trouble.</p>
											<ul className="list-style-one">
												<li>Develop new ideas and market them</li>
												<li>Build leadership and management skills</li>
												<li>Improve manufacturing processes</li>
												<li>Build a business strategy and plan</li>
												<li>Demoralized by the charm of the moment</li>
											</ul>
										</div>
									
										<div className="column col-lg-6 col-md-6 col-sm-12">
											<h4>Competitors Research & Analysis</h4>
											<div className="graph-image">
												<img src={Graph} alt="" />
											</div>
										</div>
									</div>
								</div>
							
								
								<div className="accordian-box">
									<h4>Research Beyond The Business Plan</h4>
									
									
									<ul className="accordion-box">

									
										<li className="accordion block active-block">
											<div className="acc-btn active"><div className="icon-outer"><span className="icon icofont-plus"></span> <span className="icon icofont-minus"></span></div>Client Prospecting</div>
											<div className="acc-content current">
												<div className="content">
													<div className="accordian-text">We also ensure that the whole team is included in the process and that no one is left out during the turnaround. The most crucial part is ensuring some degree of financial stability during the turnaround.</div>
												</div>
											</div>
										</li>

										
										<li className="accordion block">
											<div className="acc-btn"><div className="icon-outer"><span className="icon icofont-plus"></span> <span className="icon icofont-minus"></span></div>Website Research</div>
											<div className="acc-content">
												<div className="content">
													<div className="accordian-text">We also ensure that the whole team is included in the process and that no one is left out during the turnaround. The most crucial part is ensuring some degree of financial stability during the turnaround.</div>
												</div>
											</div>
										</li>
										
										
										<li className="accordion block">
											<div className="acc-btn"><div className="icon-outer"><span className="icon icofont-plus"></span> <span className="icon icofont-minus"></span></div>Grant & Funding Research</div>
											<div className="acc-content">
												<div className="content">
													<div className="accordian-text">We also ensure that the whole team is included in the process and that no one is left out during the turnaround. The most crucial part is ensuring some degree of financial stability during the turnaround.</div>
												</div>
											</div>
										</li>
									
									</ul>
									
								</div>
								
							</div>
						</div>
					</div>
				</div>
				
				
                <div className="sidebar-side col-lg-3 col-md-12 col-sm-12">
                	<aside className="sidebar sticky-top">
						
						
                        <div className="sidebar-widget categories-widget">
							
                        	<div className="category-boxed">
								<Link to="#" className="overlay-link"></Link>
								<div className="boxed-inner">
                                
                    <img src={Plan} alt="about" />
               
									<h6>Financial Planning</h6>
								</div>
							</div>
							
							
							
							<div className="category-boxed">
								<Link to="#" className="overlay-link"></Link>
								<div className="boxed-inner">
                                <img src={Hand} alt="about" />
									<h6>Investment Advisory</h6>
								</div>
							</div>
							
							<div className="category-boxed">
								<Link to="#" className="overlay-link"></Link>
								<div className="boxed-inner">
                                <img src={Tax} alt="about" />
									<h6>Tax Planning</h6>
								</div>
							</div>
							
							<div className="category-boxed">
								<Link to="#" className="overlay-link"></Link>
								<div className="boxed-inner">
                                <img src={Bank} alt="about" />
									<h6>Corporate Finance</h6>
								</div>
							</div>
							
							
						</div>
						
						
                        <div className="sidebar-widget brochures-widget">
							<div className="widget-content">
								<h4>Our brochures</h4>
								<div className="text">Download our comapny profile & 2017 financial brochure.</div>
								<ul className="brochure-list">
									<li><Link to="#"><span className="icon far fa-file-pdf"></span>Donwload PDF</Link></li>
									<li><Link to="#"><span className="icon far fa-file-word"></span>Donwload DOC</Link></li>
								</ul>
							</div>
						</div>
						
					
                        <div className="sidebar-widget enquiry-widget">
							<div className="widget-content">
								<h4>For Business Enquiry</h4>
								<div className="text">You can also send us an email and we’ll get in touch shortly, or Troll Free</div>
								<div className="number">Number :<Link to="tel:+91-00-700-6202">+961 76 157 762</Link></div>
								<Link to="#" className="theme-btn btn-style-two"><span className="txt">Send Email</span></Link>
							</div>
						</div>
						
					</aside>
				</div>
				
			</div>
		</div>
	</div>
      <Footer />
	  <div
        ref={scrollLinkRef}
        className="scroll-to-top"
        data-target="html"
        style={{ display: showScroll ? 'block' : 'none' }}
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
      >
        <span className="fa fa-arrow-circle-up"></span>
      </div>
    </div>
  );
};

export default ServiceDetail;
