
import Header from '../../src/components/header'
import Footer from '../../src/components/footer'
import React, { useEffect ,useState,useRef} from 'react';
import { Carousel } from 'react-bootstrap';
import Slider1 from '../../src/assets/slider1.jpg';
import Plan from '../../src/assets/plan.png';
import Hand from '../../src/assets/hand.png';
import Tax from '../../src/assets/tax.png';
import Bank from '../../src/assets/bank.png';
import Slider2 from '../../src/assets/slider2.jpg';
import Space from '../../src/assets/space.png';
import Test from '../../src/assets/test.png';
import Marquee from '../../src/assets/marquee.png';
import HomeAbout from '../../src/assets/home-about.png';
import Team1 from '../../src/assets/team-1.jpg';
import Team2 from '../../src/assets/team-2.jpg';
import Team3 from '../../src/assets/team-3.jpg';
import Port from '../../src/assets/port.jpg';
import T1 from '../../src/assets/t1.jpg';
import T2 from '../../src/assets/t2.jpg';
import T3 from '../../src/assets/t3.jpg';
import Phone from '../../src/assets/phone.jpg';
import '../css/bootstrap.min.css';
import Money from '../../src/assets/money.jpg';
import { Link } from 'react-router-dom';

function Home() {
    
    const [isFixed, setIsFixed] = useState(false);
  const [showScroll, setShowScroll] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };
    
    window.addEventListener('resize', handleResize);
    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const scrollLinkRef = useRef(null);


  const handleScroll = () => {
    const windowPos = window.scrollY;

    if (windowPos > 100) {
      setIsFixed(true);
      setShowScroll(true);
    } else {
      setIsFixed(false);
      setShowScroll(false);
    }
  };

  useEffect(() => {
  
    window.addEventListener('scroll', handleScroll);

    
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


    return (
        <>
           
            <div className="page-wrapper">

                <Header/>
                <div id="mainCarousel" className="carousel slide banner-section" data-bs-ride="carousel" data-bs-interval="4000">
  <div className="carousel-indicators">
    <button type="button" data-bs-target="#mainCarousel" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#mainCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
  </div>

  <div className="carousel-inner">

    <div className="carousel-item active">
      <div className="slide" style={{ backgroundImage: `url(${Slider1})`, backgroundSize: 'cover' }}>
        <div className="auto-container w-100">
          <div className="row clearfix">
            <div className="content-column col-lg-7 col-md-12 col-sm-12">
              <div className="inner-column">
                <div>
            <h1>Your Capital's <span>Safe Haven</span> </h1>
                 <span style={{fontSize:'20px',color:'gray'}}>Unlock financial success with our expert-driven, personalized investment strategies that deliver consistent growth and security</span>
                </div>
                
                <div className="text">
                 At  <b>Beta</b>, we are dedicated to helping you grow and protect your wealth. With a focus on personalized investment strategies and a deep understanding of global markets, we offer unique opportunities that cater to your financial goals. Whether you're a seasoned investor or just starting, our team of experts is committed to guiding you through every step of your investment journey.
                </div>
                <div className="btn-box">
                  <Link to="/service" className="theme-btn btn-style-one"><span className="txt">Start Your Investment Journey</span></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* Slide 2 */}
    <div className="carousel-item">
      <div className="slide" style={{ backgroundImage: `url(${Slider2})`, backgroundSize: 'cover' }}>
        <div className="auto-container w-100">
          <div className="row clearfix">
            <div className="content-column col-lg-7 col-md-12 col-sm-12">
              <div className="inner-column">
                <h1>Who <span>We Are?</span></h1>
                <div className="text">
                  <b>At Beta</b>, we are a team of seasoned financial experts dedicated to helping individuals and institutions achieve their financial goals through strategic investments. With a wealth of experience across global markets, we specialize in creating personalized, research-driven investment solutions that deliver consistent results.
                  <br />
                  Our mission is to provide a balanced approach to wealth management, combining innovative strategies with a deep commitment to transparency and client success. Whether you're looking to grow your portfolio, safeguard your assets, or explore new investment opportunities, we’re here to guide you every step of the way.
                </div>
                <div className="btn-box">
                  <Link to="/about" className="theme-btn btn-style-one"><span className="txt">Know More About Beta</span></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <button className="carousel-control-prev" type="button" data-bs-target="#mainCarousel" data-bs-slide="prev">
  <span 
  aria-hidden="true" 
  style={{
    display: 'inline-block',
    width: '20px', 
    height: '20px', 
    borderRight: '2px solid lightgreen', 
    borderBottom: '2px solid green', 
    transform: 'rotate(135deg)', 
    marginRight: '5px' 
  }}
></span>

      <span className="visually-hidden">Previous</span>

    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#mainCarousel" data-bs-slide="next">
  <span 
  aria-hidden="true" 
  style={{
    display: 'inline-block',
    width: '20px', // Adjust width for the arrow
    height: '20px', // Adjust height for the arrow
    borderRight: '2px solid green', // Thin gray right border
    borderBottom: '2px solid lightgreen', // Thin gray bottom border
    transform: 'rotate(-43deg)', // Rotates to create the arrow
    marginRight: '5px' // Space between arrow and text
  }}
></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>


                <div className="cta-section" data-bg-image={Marquee}>
                    <div className="auto-container">

                        <div className="row align-items-center">

                            <div className="col-lg-7">

                                <div className="cta-content">
                                    <h3 className="title">Book an appointment quickly? <span className="text-bold">Call us now!</span></h3>
                                    <p>We prodive a dedicated support 24/7 for any your question</p>
                                </div>

                            </div>
                            <div className="col-lg-5">

                                <div className="cta-phone text-lg-end text-strat">
                                    <h2 className="title">+961 76 157 762</h2>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

                <div className="about-section">
                    <div className="auto-container">
                        <div className="inner-container">
                            <div className="row align-items-center clearfix">

                                <div className="image-column col-lg-6">
                                    <div className="about-image">
                                        <div className="about-inner-image">
                                        <img src={HomeAbout} alt="about" style={{marginBottom:'80px'}}/>
                                        </div>
                                    </div>
                                </div>


                                <div className="content-column col-lg-6 col-md-12 col-sm-12 mb-0">
                                    <div className="about-column">
                                        <div className="sec-title">
                                            <div className="title">about beta</div>
                                            <h2>Your <span>Trusted Partner</span> <br></br> in Strategic Wealth Growth                                            </h2>
                                        </div>
                                        <h4 style={{fontWeight:'bold',color:'black'}}>Welcome to <span style={{color:'#4ab87f'}}>Beta</span></h4>
                                        <div className="text">
                                        <p style={{fontSize:'16px'}}>We’re delighted to have you here! At Beta, we specialize in guiding investors through the complexities of wealth management with personalized, strategic investment solutions. Our expert team is committed to helping you navigate the financial landscape, whether you're seeking to grow your assets, protect your wealth, or explore new opportunities.</p>
                                         <p style={{fontSize:'16px'}}>With a focus on long-term success and tailored strategies, we ensure that your financial future is in trusted hands.</p>
                                         <h5 style={{textAlign:'center',fontWeight:'bold',color:'black'}}>Welcome to a new era of smart, informed investing!
                                         </h5>
                                         <div className="learn-more" style={{ borderRadius: '5px' ,textAlign:'center',marginTop:'20px' }}>
                                        <Link to="/about" className="btn btn-success">Learn More</Link>
                                    </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>


                <div className="services-section" style={{ padding: '20px 0' }}>
                    <div className="auto-container" style={{ padding: '0 15px' }}>
                      
                        <div className="row no-gutters">
                            {/* Left Section */}
                            <div className="col-lg-4 col-md-12 left-section">
                                <div className="service-info">
                                    <h2 style={{fontWeight:'bold',color:'black'}}>Our Services</h2>
                                    <p style={{ fontSize: '20px' }}> Comprehensive Investment Solutions for Lasting Financial Success</p>
                                    <div className="learn-more" style={{ marginBottom: '10px', borderRadius: '5px' }}>
                                        <Link to="/service" className="btn btn-success">Learn More</Link>
                                    </div>
                                </div>
                            </div>
                            {/* Right Section: Services */}
                            <div className="col-lg-8 col-md-12 right-section" style={{
    border: '2px solid #ddd',
    padding: '0',
    marginBottom: '50px',
    display: 'flex',
    borderRadius: '5px',
    flexWrap: 'wrap',
    height: '100%',
    maxWidth: '100%', // Ensure it doesn't exceed 100%
}}>

<style>
    {`
        @media (max-width: 576px) {
            .right-section {
                width: 100%; // Adjust width for small screens
                height: auto; // Change to auto to fit content
                margin: auto; // Center the section
                padding: 0; // Remove padding around the section
            }
            .service-block {
                padding: 5px; // Minimal padding for service blocks
                margin: 0; // Remove margin if needed
            }
            .inner-box {
                padding: 5px; // Minimal padding for inner boxes
                height: auto; // Allow height to adjust
            }
            .text {
                font-size: 0.8em; // Adjust text size for better fit
            }
        }
    `}
</style>

<div className="row g-0" style={{ width: '100%' }}>
    {/* Financial Planning Block */}
    <div className="col-lg-6 col-md-6 col-sm-12" style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0',
        borderRight: '1px solid #ddd',
        borderBottom: '1px solid #ddd',
        height: 'auto', // Change to auto for better fit
    }}>
        <div className="service-block" style={{ flex: 1 }}>
            <div className="inner-box" style={{ padding: '5px', textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <div className="icon-box">
                    <img src={Hand} alt="about" />
                </div>
                <h5><Link to="/service-detail"> Portfolio management</Link></h5>
                <div className="text">Tailored Investing to your financial goals and risk appetite.</div>
                <Link className="read-more" href="/service-detail">More <span className="ti-angle-right"></span></Link>
            </div>
        </div>
    </div>

    {/* Investment Advisory Block */}
    <div className="col-lg-6 col-md-6 col-sm-12" style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0',
        borderRight: '1px solid #ddd',
        borderBottom: '1px solid #ddd',
        height: 'auto', // Change to auto for better fit
    }}>
        <div className="service-block" style={{ flex: 1 }}>
            <div className="inner-box" style={{ padding: '5px', textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <div className="icon-box">
                    <img src={Plan} alt="about" />
                </div>
                <h5><Link to="/service-detail">Investment Advisory</Link></h5>
                <div className="text">Personalized Investment Solutions designed to meet the unique needs of each client.
                </div>
                <Link className="read-more" href="/service-detail">More <span className="ti-angle-right"></span></Link>
            </div>
        </div>
    </div>

    {/* Tax Planning Block */}
    <div className="col-lg-6 col-md-6 col-sm-12" style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0',
        borderRight: '1px solid #ddd',
        borderBottom: '1px solid #ddd',
        height: 'auto', // Change to auto for better fit
    }}>
        <div className="service-block" style={{ flex: 1 }}>
            <div className="inner-box" style={{ padding: '5px', textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <div className="icon-box">
                    <img src={Bank} alt="about" />
                </div>
                <h5><Link to="/service-detail">Wealth Planning</Link></h5>
                <div className="text">Create a long-term wealth management plan encompassing your investment strategy, tax planning, retirement goals, and more.
                </div>
                <Link className="read-more" href="/service-detail">More <span className="ti-angle-right"></span></Link>
            </div>
        </div>
    </div>

    {/* Corporate Finance Block */}
    <div className="col-lg-6 col-md-6 col-sm-12" style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0',
        height: 'auto', // Change to auto for better fit
    }}>
        <div className="service-block" style={{ flex: 1 }}>
            <div className="inner-box" style={{ padding: '5px', textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <div className="icon-box">
                    <img src={Tax} alt="about" />
                </div>
                <h5><Link to="/service-detail">Corporate financial crisis management</Link></h5>
                <div className="text">Specialize in understanding the local market and economic challenges, offering investment options that align with Lebanon's specific financial landscape
                </div>
                <Link className="read-more" href="/service-detail">More <span className="ti-angle-right"></span></Link>
            </div>
        </div>
    </div>
</div>
</div>


                        </div>
                    </div>
                </div>

                <div className="container-fluid text-white py-4" 
     style={{
         background: '#002147',
         position: 'relative',
         overflow: 'hidden',
         color: '#ffffff'
     }}>

                    <div className="row align-items-center">
                        {/* Left Section (Heading and Button) */}
                        <div className="col-md-5 text-center text-md-left">
                            <h1 className="display-4 font-weight-bold">Our Proven Investment Process</h1>
                            <h2 className="h4 font-weight-light mb-4">Strategic, Transparent, and Tailored to You</h2>
                            <button className="btn btn-outline-light rounded-pill px-4 py-2 mb-2"
                                style={{
                                    transition: 'background 0.3s, transform 0.3s',
                                    border: '2px solid #ffffff',
                                    color: '#ffffff',
                                    backgroundColor: 'transparent',
                                    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.3)'
                                }}
                                onMouseEnter={(e) => {
                                    e.currentTarget.style.transform = 'scale(1.05)';
                                    e.currentTarget.style.backgroundColor = '#43B67A';
                                    e.currentTarget.style.color = '#005f5f';
                                }}
                                onMouseLeave={(e) => {
                                    e.currentTarget.style.transform = 'scale(1)';
                                    e.currentTarget.style.backgroundColor = 'transparent';
                                    e.currentTarget.style.color = '#ffffff';
                                }}>
                               <Link to='/contact' style={{color:'white'}}>Contact Us</Link>
                            </button>
                        </div>

                        {/* Right Section (Flowchart Steps Layout) */}
                        <div className="col-md-7 d-flex justify-content-center mt-3">
                            <div className="process-flow d-flex flex-column align-items-start" style={{ position: 'relative'}}>
                                {[
                                    {
                                        icon: "fas fa-users",
                                        title: "Exclusive Investment Insight Session",
                                        description: "Our Exclusive Investment Insight Session offers a personalized, in-depth consultation designed to align your financial goals with strategic investment opportunities. During this session, our experts will assess your current financial situation, discuss tailored strategies, and provide actionable insights to help you achieve lasting growth.",
                                    },
                                    {
                                        icon: "fas fa-chart-line",
                                        title: "Define Your Personalized Risk Blueprint",
                                        description: "Analyze cash flow, income, expenses, debts, assets, liabilities, and financial ratios. Identify potential risks and client's risk tolerance.",
                                    },
                                    {
                                        icon: "fas fa-rocket",
                                        title: "Embark on Your Wealth-Building Journey",
                                        description: "Select your esteemed brokerage partner with Beta, securely deposit your assets, and begin your journey toward exceptional wealth growth.",
                                    },
                                ].map((step, index) => (
                                    <div key={index} className="d-flex align-items-start" style={{ position: 'relative', paddingLeft: '2rem' }}>
                                        {/* Step Number and Line */}
                                        <div style={{ position: 'absolute', left: '0', textAlign: 'center', width: '3rem' }}>
                                            <div className="step-number" style={{
                                                width: '2rem',
                                                height: '2rem',
                                                borderRadius: '50%',
                                                backgroundColor: '#43B67A',  /* Lighter teal for the step number */
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                color: '#ffffff',
                                                marginBottom: '0.5rem',  /* Reduced space between number and line */
                                            }}>
                                                {`0${index + 1}`}
                                            </div>
                                            {/* Line between steps */}
                                            {index < 2 && (
                                                <div style={{ width: '2px', height: '50px', backgroundColor: '#43B67A', margin: '0 17px 0' }}></div>  
                                            )}
                                        </div>

                                        {/* Icon and Step Content */}
                                        <div style={{ flexGrow: 1, marginLeft: '2rem' }}>
                                            <div className="d-flex align-items-center mb-2">
                                                {/* Icon */}
                                                <i className={`${step.icon} step-icon`} style={{
                                                    fontSize: '1.8rem',  /* Slightly smaller icon size */
                                                    color: '#43B67A',
                                                    transition: 'color 0.3s, transform 0.3s',
                                                    marginRight: '0.8rem',  /* Reduced margin between icon and title */
                                                }}
                                                    onMouseEnter={(e) => {
                                                        e.currentTarget.style.color = '#ffffff';
                                                        e.currentTarget.style.transform = 'scale(1.2)';
                                                    }}
                                                    onMouseLeave={(e) => {
                                                        e.currentTarget.style.color = '#43B67A';
                                                        e.currentTarget.style.transform = 'scale(1)';
                                                    }}></i>

                                                <h3 className="h5 font-weight-bold">{step.title}</h3>
                                            </div>

                                            <p style={{
                                                color: '#ffffff',  /* Set description text color to white */
                                                backgroundColor: 'rgba(255, 255, 255, 0.05)',  /* Soft background for the description */
                                                padding: '0.8rem',  /* Reduced padding */
                                                borderRadius: '8px',
                                                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',  /* Reduced shadow strength */
                                                lineHeight: '1.5'
                                            }}>
                                                {step.description}
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <img src={Space} alt="about" width={350} height={400}/>
                <section
                    style={{
                        backgroundColor: '#4ab87f',
                        color: 'white',
                        padding: '10px', // Reduced padding
                    }}
                    className="testimonial-section"
                >
                    <div className="auto-container">
                        <div className="row mb-3"> {/* Reduced margin-bottom */}
                            <div
                                className="col-md-5 d-flex flex-column align-items-center text-center"
                                style={{ marginBottom: '1em' }} // Reduced margin-bottom
                            >
                                <br />
                                <img
                                    src={Test}
                                    alt="Client Image"
                                    style={{
                                        width: '150px',
                                        marginBottom: '10px', // Reduced margin-bottom
                                    }}
                                />
                                <h2
                                    className="display-4"
                                    style={{ fontSize: '2rem', marginBottom: '5px' }} // Reduced font size and margin
                                >
                                    4.5
                                </h2>
                                <div
                                    className="d-flex justify-content-center mb-2" // Reduced margin-bottom
                                    style={{ fontSize: '1.5rem' }}
                                >
                                    {[...Array(4)].map((_, i) => (
                                        <span key={i} style={{ color: '#ffc107', marginRight: '3px' }}> {/* Reduced margin-right */}
                                            &#9733;
                                        </span>
                                    ))}
                                    <span
                                        className="half-star"
                                        style={{ position: 'relative', display: 'inline-block', marginRight: '3px' }} // Reduced margin-right
                                    >
                                        <span style={{ color: '#ffc107' }}>&#9733;</span>
                                        <span
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                overflow: 'hidden',
                                                width: '50%',
                                                color: '#6c757d',
                                            }}
                                        >
                                            &#9733;
                                        </span>
                                    </span>
                                </div>
                                <h5 style={{ fontWeight: 'bold', fontSize: '1rem' }}> {/* Reduced font size */}
                                    Client Success: Highlight how your firm helped clients
                                </h5>
                                <p style={{ maxWidth: '80%', fontSize: '0.8rem', lineHeight: '1.4rem' }}> {/* Reduced font size and line-height */}
                                    Full range of consultancy & training for data consultation strategic ways for business.
                                </p>
                            </div>

                            <div className="col-md-1 d-flex justify-content-center">
                                <div
                                    style={{
                                        width: '1px',
                                        height: '100%',
                                        backgroundColor: '#6c757d',
                                        margin: 'auto',
                                    }}
                                ></div>
                            </div>

                            <div className="col-md-6">
                                <br />
                                <br />
                                <Carousel controls={false} indicators={true}>
                                    <Carousel.Item>
                                        <div className="d-flex flex-column justify-content-center text-center">
                                            <h5
                                                style={{
                                                    textTransform: 'uppercase',
                                                    color: '#6c757d',
                                                    fontSize: '1rem',
                                                }}
                                            >
                                                Client Stories
                                            </h5>
                                            <h2 style={{ fontWeight: 'bold', fontSize: '1.75rem' }}>
                                                Achieving Financial Goals Together
                                            </h2>
                                            <p
                                                style={{
                                                    marginTop: '15px',
                                                    fontSize: '0.9rem',
                                                    lineHeight: '1.5rem',
                                                }}
                                            >
                                                There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.
                                            </p>
                                            <div className="d-flex align-items-center justify-content-center mt-4">
                                                <img
                                                    src={T1}
                                                    alt="Author Image"
                                                    style={{
                                                        borderRadius: '50%', marginRight: '15px', width: '70px',
                                                        height: '70px',
                                                    }}
                                                />
                                                <div>
                                                    <h6 style={{ margin: 0, fontWeight: 'bold', fontSize: '1rem' }}>
                                                        Jesonal Jelins
                                                    </h6>
                                                    <span style={{ color: '#6c757d', fontSize: '0.85rem' }}>
                                                        Team Captain
                                                    </span>
                                                </div>
                                            </div>
                                            <div>
                                                <h6 style={{ margin: 0, fontWeight: 'bold', fontSize: '1rem', color: '#4ab87f' }}>
                                                    .
                                                </h6>

                                                <h6 style={{ margin: 0, fontWeight: 'bold', fontSize: '1rem', color: '#4ab87f' }}>
                                                    .
                                                </h6>
                                            </div>
                                        </div>
                                    </Carousel.Item>

                                    <Carousel.Item>
                                        <div className="d-flex flex-column justify-content-center text-center">
                                            <h5
                                                style={{
                                                    textTransform: 'uppercase',
                                                    color: '#6c757d',
                                                    fontSize: '1rem',
                                                }}
                                            >
                                                Client Stories
                                            </h5>
                                            <h2 style={{ fontWeight: 'bold', fontSize: '1.75rem' }}>
                                                Empowering Clients for Success
                                            </h2>
                                            <p
                                                style={{
                                                    marginTop: '15px',
                                                    fontSize: '0.9rem',
                                                    lineHeight: '1.5rem',
                                                }}
                                            >
                                                Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old.
                                            </p>
                                            <div className="d-flex align-items-center justify-content-center mt-4">
                                                <img
                                                    src={T2}
                                                    alt="Author Image"
                                                    style={{
                                                        borderRadius: '50%', marginRight: '15px', width: '70px',
                                                        height: '70px',
                                                    }}
                                                />
                                                <div>
                                                    <h6 style={{ margin: 0, fontWeight: 'bold', fontSize: '1rem' }}>
                                                        Anna Smith
                                                    </h6>
                                                    <span style={{ color: '#6c757d', fontSize: '0.85rem' }}>
                                                        Project Lead
                                                    </span>
                                                </div>
                                            </div>
                                            <div>
                                                <h6 style={{ margin: 0, fontWeight: 'bold', fontSize: '1rem', color: '#4ab87f' }}>
                                                    .
                                                </h6>

                                                <h6 style={{ margin: 0, fontWeight: 'bold', fontSize: '1rem', color: '#4ab87f' }}>
                                                    .
                                                </h6>
                                            </div>
                                        </div>
                                    </Carousel.Item>

                                    <Carousel.Item>
                                        <div className="d-flex flex-column justify-content-center text-center">
                                            <h5
                                                style={{
                                                    textTransform: 'uppercase',
                                                    color: '#6c757d',
                                                    fontSize: '1rem',
                                                }}
                                            >
                                                Client Stories
                                            </h5>
                                            <h2 style={{ fontWeight: 'bold', fontSize: '1.75rem' }}>
                                                Building Long-Term Financial Security
                                            </h2>
                                            <p
                                                style={{
                                                    marginTop: '15px',
                                                    fontSize: '0.9rem',
                                                    lineHeight: '1.5rem',
                                                }}
                                            >
                                                The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum".
                                            </p>
                                            <div className="d-flex align-items-center justify-content-center mt-4">
                                                <img
                                                    src={T3}
                                                    alt="Author Image"
                                                    style={{
                                                        borderRadius: '50%', marginRight: '15px', width: '70px',
                                                        height: '70px',
                                                    }}
                                                />
                                                <div>
                                                    <h6 style={{ margin: 0, fontWeight: 'bold', fontSize: '1rem' }}>
                                                        Mark Johnson
                                                    </h6>
                                                    <span style={{ color: '#6c757d', fontSize: '0.85rem' }}>
                                                        Senior Consultant
                                                    </span>
                                                </div>
                                            </div>
                                            <div>
                                                <h6 style={{ margin: 0, fontWeight: 'bold', fontSize: '1rem', color: '#4ab87f' }}>
                                                    .
                                                </h6>

                                                <h6 style={{ margin: 0, fontWeight: 'bold', fontSize: '1rem', color: '#4ab87f' }}>
                                                    .
                                                </h6>
                                            </div>
                                        </div>
                                    </Carousel.Item>
                                </Carousel>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="reputation-section-two style-two">
                    <div className="auto-container">
                       <div className="row clearfix">


                            <div className="form-column col-lg-5 col-md-12 col-sm-12">
                                <div className="inner-column">
                                    <div className="form-boxed">
                                        <h5>free consulation</h5>

                                        <div className="consult-form">
                                            <form method="post" action="donate.html">


                                                <div className="form-group">
                                                    <label>full name</label>
                                                    <input type="text" name="name" placeholder="Enter Your Full Name" required />
                                                </div>

                                                <div className="form-group">
                                                    <label>phone number</label>
                                                    <input type="text" name="phone" placeholder="Enter your phone number" required />
                                                </div>

                                                <div className="form-group">
                                                    <label>Solutions (dropdown):</label>
                                                    <select className="custom-select-box">
                                                        <option>Choose topic</option>
                                                        <option>Portfolio Management</option>
                                                        <option>Investment advisory
                                                        </option>
                                                        <option>Wealth Planning</option>
                                                        <option>Corporate financial crisis management</option>
                                                    </select>
                                                </div>

                                                <div className="form-group">
                                                    <label>message</label>
                                                    <textarea name="message" placeholder="Write your message here"></textarea>
                                                </div>
                                                <div className="form-group">
                                                    <button className="theme-btn btn-style-one" type="submit" name="submit-form"><span className="txt">send your messenger</span></button>
                                                </div>
                                            </form>

                                        </div>
                                    </div>

                                </div>
                            </div>


                            <div className="content-column col-lg-7 col-md-12 col-sm-12">
                                <div className="inner-column">
                                    <div className="sec-title">
                                        <div className="title">why choose us?</div>
                                        <h2>Where Expertise Meets Exceptional Growth</h2>
                                    </div>
                                    <div className="blocks-outer">


                                        <div className="reputation-block">
                                            <div className="inner-box">
                                                <h5>Expertise</h5>
                                                <div className="text">Our team brings decades of combined experience in managing high-value portfolios.                                                .</div>
                                            </div>
                                        </div>


                                        <div className="reputation-block">
                                            <div className="inner-box">
                                                <h5>Personalized Service</h5>
                                                <div className="text">We tailor our investment strategies to fit your specific goals and risk tolerance</div>
                                            </div>
                                        </div>


                                        <div className="reputation-block">
                                            <div className="inner-box">
                                                <h5>Proven Results</h5>
                                                <div className="text">With a strong track record of outperforming the market, we consistently deliver returns for our clients.</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="experts-section" style={{ padding: '20px' }}>
  <div className="auto-container" style={{ maxWidth: '1200px', margin: '0 auto' }}>
    <div className="sec-title">
      <h2 style={{ marginBottom: '20px' }}>Letter from the CEO</h2>
    </div>

    <div className="row clearfix" style={{ display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row', alignItems: 'center' }}>
      <div className="team-block" style={{ flex: '1', display: 'flex', alignItems: 'center', marginBottom: isSmallScreen ? '20px' : '0' }}>
        <div className="inner-box" style={{ flex: '1', display: 'flex', flexDirection: isSmallScreen ? 'column' : 'row', alignItems: isSmallScreen ? 'center' : 'flex-start' }}>
          <div className="image" style={{ marginRight: isSmallScreen ? '0' : '20px', marginBottom: isSmallScreen ? '10px' : '0' }}>
            <Link to="#">
              <img src={Team1} alt="CEO" style={{ width: '100%' }} />
            </Link>
            <ul className="social-box" style={{ display: 'flex', gap: '6px', padding: '4px', listStyle: 'none', width: '200px' }}>
              {['facebook', 'twitter', 'instagram', 'whatsapp'].map((platform, index) => (
                <li key={index}>
                  <Link
                    to={`https://${platform}.com/`}
                    style={{
                      width: '30px',
                      height: '30px',
                      borderRadius: '15px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      border: '1px solid #ccc',
                      backgroundColor: '#f9f9f9',
                      transition: 'background-color 0.3s ease, border-color 0.3s ease',
                      textDecoration: 'none',
                      color: 'inherit',
                      position: 'relative',
                    }}
                    aria-label={platform}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.backgroundColor = platform === 'facebook' ? '#1877f2' : platform === 'twitter' ? '#1da1f2' : platform === 'instagram' ? '#dd2a7b' : '#25d366';
                      e.currentTarget.style.color = 'white';
                      e.currentTarget.style.borderColor = e.currentTarget.style.backgroundColor;
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.backgroundColor = '#f9f9f9';
                      e.currentTarget.style.color = 'inherit';
                      e.currentTarget.style.borderColor = '#ccc';
                    }}
                  >
                    <i className={`icofont-${platform}`} style={{ fontSize: '16px' }}></i>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div style={{ flex: '1', marginLeft: isSmallScreen ? '0' : '20px', color: 'black', textAlign: isSmallScreen ? 'center' : 'left' }}>
            <p style={{ fontSize: '17px', lineHeight: '1.6' }}>
              Dear Valued Stakeholders, <br /><br />
              As we reflect on our journey at Beta Investment Group, I am filled with a profound sense of gratitude and optimism. 
              Since our establishment in 2020, our commitment has been to navigate the complex financial landscape and provide tailored 
              solutions that empower the Lebanese people. <br /><br />
              In a time marked by economic challenges, we have remained steadfast in our mission. Our team has worked diligently to 
              adapt our strategies, ensuring that we not only meet the needs of our clients but also anticipate future trends in the market. 
              We believe in the power of innovation and collaboration... <br /><br />
              Sincerely,<br />
              Raoul Aoun,<br />
              CEO, Beta Investment Group
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>






                <div className="blog-section">
                    <div className="auto-container">

                        <div className="sec-title centered">
                            <div className="title">our blog</div>
                            <h2><span>Latest </span>From Our Press</h2>
                        </div>
                        <div className="inner-container">
                            <div className="clearfix row g-0">

                                <div className="column col-lg-8 col-md-12 col-sm-12">


                                    <div className="news-block">
                                        <div className="inner-box">
                                            <div className="clearfix">

                                                <div className="image-column col-lg-6 col-md-6 col-sm-12">
                                                    <div className="inner-column">
                                                        <div className="image">
                                                            <Link to="blog-detail.html"><img src={Port} alt="" /></Link>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="content-column col-lg-6 col-md-6 col-sm-12">
                                                    <div className="inner-column">
                                                        <div className="arrow-one"></div>
                                                        <div className="title">business</div>
                                                        <h4><Link to="blog-detail.html">Problems About <br></br> Social Insurance For <br></br> Truck Drivers</Link></h4>
                                                        <div className="post-date">Decmber 14th, 2020 by <span>Admin</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="news-block">
                                        <div className="inner-box">
                                            <div className="clearfix row g-0">

                                                <div className="content-column col-lg-6 col-md-6 col-sm-12 order-lg-1 order-2">
                                                    <div className="inner-column">
                                                        <div className="arrow-two"></div>
                                                        <div className="title">bank & finance</div>
                                                        <h4><Link to="blog-detail.html">Payment Online - <br></br> Things That You Know To <br></br> Project Your Money</Link></h4>
                                                        <div className="post-date">Decmber 14th, 2020 by <span>Admin</span></div>
                                                    </div>
                                                </div>

                                                <div className="image-column col-lg-6 col-md-6 col-sm-12 order-lg-2 order-1">
                                                    <div className="inner-column">
                                                        <div className="image">
                                                            <Link to="blog-detail.html"><img src={Money} alt="" /></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>


                                <div className="column col-lg-4 col-md-12 col-sm-12">

                                    <div className="news-block-two">
                                        <div className="inner-box">
                                            <div className="image">
                                                <Link to="blog-detail.html"><img src={Phone} alt="" /></Link>
                                                <div className="arrow"></div>
                                            </div>
                                            <div className="lower-content">
                                                <div className="title">tips & tricks</div>
                                                <h4><Link to="/blog-detail">5 Secrets To <br></br> Coaching Your Employees <br></br> To Greatness</Link></h4>
                                                <div className="post-date">Decmber 14th, 2020 by <span>Admin</span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="map-section">
                    <div className="contact-map-area">
                        <iframe className="contact-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26496.135822635544!2d35.48368275677567!3d33.889216585979106!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x151f17215880a78f%3A0x729182bae99836b4!2sBeirut!5e0!3m2!1sen!2slb!4v1726905426482!5m2!1sen!2sl" allowFullScreen="" aria-hidden="false" tabIndex="0"></iframe>
                    </div>
                </div>

                <Footer />

            </div>

            <div className="search-popup">
                <button className="close-search style-two"><span className="icofont-brand-nexus"></span></button>
                <button className="close-search"><span className="icofont-arrow-up"></span></button>
                <form method="post" action="blog.html">
                    <div className="form-group">
                        <input type="search" name="search-field" placeholder="Search Here" required="" />
                        <button type="submit"><i className="fa fa-search"></i></button>
                    </div>
                </form>
            </div>

            <div
        ref={scrollLinkRef}
        className="scroll-to-top"
        data-target="html"
        style={{ display: showScroll ? 'block' : 'none' }}
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
      >
        <span className="fa fa-arrow-circle-up"></span>
      </div>

        </>
    );
}

export default Home;
